<template>
    <div class="wrapper">
        <section>
            <div class="header">
                <h2>violence risk reduction program</h2>
                <div class="img">
                    <div class="text">
                        <p>"It is true that a victim who fights back may suffer for it, but one who does not almost
                            certainly will suffer for it"</p>
                        <br>
                        <h4>
                            Jeff Cooper
                        </h4>
                    </div>
                    <img :src=img1>
                </div>
            </div>
        </section>
    </div>
    <!-- <section class="container">
        <h3>we offer empowerment through self defence</h3>
        <p class="bold">Is self defense training really an effective deterrent
            to sexual violence?</p>
        <p>
            Yes it is, and here is the evidence.
            Available evidence in support of a "physical response to
            sexual assault are not anecdotal. There are several documented
            cases of real women in real threatening situations who succeeded
            in avoiding assault simply by fighting back, examples include,
            French journalist <a href="http://www.madmoizelle.com/agression-mais-ca-va- 134439)"> <b>Jackie Parker "I've
                    been attacked, but
                    I'm fine"</b></a>
            ESPN Executive <a href="http://www.marieclaire.com/sex-love/relationship-
issues/escape-from-rapist)"><b>Keri Potts ("How I Escaped a
                    Rapist")</b></a> or
            <a href="http://www.telegraph.co.uk/news/uknews/crime/11384689/
Mother-bit-Of-sex-attackers-tonaue-to-secure-DiNA-
evidence.html"> <b>Adele Barber</b> (The Telegraph)</a>, and many others. Research conducted since
            the 70s has consistently supported the conclusion that fighting
            back is actual the most effective strategy to thwart sexual
            assaults.
            Studies such as Kleck & Tark (2005) or Reekie & Wilson
            (1993) or Ullman & Knight (1992), provide sufficient evidence
            to support the fact that women who respond with phvsical and
            verbal resistance to the offender's violent attack significantly
            reduce the probability that rape will occur.
            In the 1990's, German commissioner <b>Susanne Paul</b> examined
            522 cases of rapes and attempted rapes to see whether fighting
            back was a strategy, Result and
            success rate. <a href="http://www.zanshin-siegerland.de/Wehren.html"><b>Irène Zeilinger</b></a>
            , director of the NGO Garance, says that data
            they collect indicate a 90% success rate
            <a href="http://www.rue89.com/2013/01/30/mesdames-contre-les-
agressions-rien-ne
-vaut-la-baston-238912)">("Ladies, against
                assaults nothing matches fighting )</a>
        </p>

        <br>
        <p>Violence, especially of a sexual nature is a serious problem
            that can have lasting, harmful effects on victims, their family,
            friends, and communities. The goal of sexual violence prevention
            is simple, to stop it from happening in the first place. The
            solutions, however, are just as complex as the problem.</p>

        <br>
        <p>Prevention efforts should ultimately decrease the number or
            individuals who perpetrate sexual violence and the number of
            individuals who are victims. Many prevention approaches aim
            to reduce risk factors and promote protective factors for
            sexual violence. In addition, comprehensive prevention strategies
            should address factors at each of the levels that influence
            sexual violence in individual, relationship, community, and
            society.</p>

        <br>
        <p>The most common prevention strategies currently focus on
            the victim, the perpetrator, or bystanders. Strategies that try
            to equip a potential victim with knowledge, awareness, or
            self-defense skills are referred to as "risk reduction techniques "</p>

        <br>
        <p>Self-defence training is about empowering women. Its about
            giving them the choice. It is naive to think sexual violence
            will ever go away completely. We cannot control the thinking
            or the actions or rapists, but we can empower women to detend
            themselves to prevent and survive an assault. As a society, we
            have a lona wav to a towards eradicatina sexual harassment
            and sexual assault, and until then, teaching young girls and
            women to detend themselves mav hold the kev to avoic
            becomina a victim or sexual violence</p>

        <div class="info">
            <div class="info-text">
                <p><b>Jocelyn Hollander</b>, professor of sociology who studied
                    violence against women, recently ran an experiment to
                    assess the effectiveness of self-defence training for women.
                    <a href=" http://cascade.uoregon.edu/spring2013/social-
                    sciences/are-women-safer-when-they-learn-self-defense/">read here</a>
                </p>
                <br>
                <p>The results of her study indicate that "women who took
                    a ten-week (30hrs) self-defence training were significantly
                    less likely to experience unwanted sexual contact than
                    those who didn't."!</p>
            </div>
            <div class="info-img">
                <img :src=img2>
            </div>
        </div>
        <br>
        <br>
        <h3>about the program</h3>
        <p>The
            "Train Tough, Smart, Safe" (TTSS) TTSS is a violence
            risk
            reduction program that trains women and girls to avoid
            becoming victims of violence.</p>
    </section> -->

    <section>
        <br>
        <br>
        <h2 class="subhead">Self defence training for women and girls.</h2>
        <br>
        <br>
        <p>The Train Tough Smart Safe (TTSS) is a violence risk reduction program that trains 
women and girls to avoid becoming victims of violence. While risk reduction approaches 
do not address the underlying conditions or causes of sexual violence, they are 
considered to be valuable within a continuum of prevention efforts. Recent research has 
built a case to support the effectiveness of programs such as TTSS at thwarting 
physical assault, and providing a sense of empowerment to women.</p>
<br>
<p>The TTSS program is implemented in Nigeria by Hayche Lifestyle (HL). HL is committed 
to promoting the safety, wellbeing, and quality of life of women and girls. While the 
program is presently offered only in Abuja and Enugu, trainers interested in 
implementing TTSS in other locations are invited to be trained and certified. Upon 
completion, each certified instructor is provided with the TTSS curriculum as well as 
ongoing support.</p>
<br>
<p>The program has been developed by Andrew Igbo in collaboration with a number of 
international martial arts experts. Andrew Igbo has over 30 years of experience training 
and teaching martial arts and self-defense to diverse populations around the world.</p>
<br>
        <br>
        <br>
        <br>
    </section>

    <div class="img-bg">
        <h2>our promise</h2>
        <p>Every student will
            become
            better
            mentally, physically, and
            emotionally prepared
            to stand up for and
            protect herself - and
            that the confidence she
            gains on the journey
            will transform her life</p>
    </div>

    
    <!-- <section>
        <br>
        <p>While risk reduction approaches do not address the underlying
            conditions or causes of sexual violence, they are considered
            to be valuable within a continuum of prevention efforts. Recent
            research has built a case supporting the effectiveness of
            programs such as TTS at thwarting physical assault, and
            providing a sense of empowerment to women.</p>
        <br>
        <p>TTSS is an intensive program with (3) three classes per week
            delivered over a period of 12 weeks. Each class lasts about
            an hour and a half.</p>
        <br>
        <p>TSS teaches techniques based on quick reactions, swift
            movements and leverage rather than physical strength, which
            make the techniques ideal against bigger and stronger attacker.
            TTSS techniques are easy to assimilate regardless of age,
            athletic attributes or body type.</p>
        <br>
        <p>In addition to self-defense techniques, TTSS teaches de-
            escalation, awareness, avoidance, learning how to be more
            assertive and how young girls can stand up for themselves.</p>
    </section>
    <section>
        <div class="implement">
            <h3>
                program implementation
            </h3>
            <p>The TTSS program is implemented in Nigeria by Hayche
                Lifestyle (HLife). HLife is committed to promoting the safety,
                wellbeing, and quality of life of women and girls. While the
                program is presently offered only in Abuja and Enugu, trainers
                interested in implementing TTSS in other locations are invited
                to be trained and certified,. Upon completion, each certified
                instructor is provided with the TTS curriculum as well as
                ongoing support.</p>
            <br>
            <p>The program has been developed by <b>Andrew Igbo</b> in
                collaboration with a number of international martial arts
                experts. Andrew Igbo has over 30 years experience training
                and teaching martial arts and self defense to diverse populations.</p>
        </div>
    </section> -->
</template>
<script>
export default {
    data: () => ({
        img1: require('@/assets/defence-img.jpg'),
        img2: require('@/assets/Capture.jpg'),



    })
}

</script>

<style scoped>

.subhead{
    font-family: Oswald;
    text-transform: capitalize;
    text-align: center;
    font-size: 25px;
    font-weight: 400;
}
.wrapper {
    height: 100vh;

}

.header {
    padding-top: 150px;
    position: relative;
}

.header h2 {
    /* color: var(--light, #F8F8F8); */
    text-align: center;
    font-family: Oswald;
    font-size: 50px;
    font-style: normal;
    font-weight: 300;
    line-height: 102.2%;
    /* 98.112px */
    text-transform: uppercase;
    color: var(--darker-black);
}

.img {
    height: 70vh;
    padding-top: 30px;
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.text {
    color: var(--light);
    position: absolute;
    bottom: 30px;
    z-index: 10;
    width: 40%;
    margin-left: 20px;
}



.text h4 {
    font-family: Oswald;
    font-size: 25px;
    font-weight: 300;
}

.container {
    /* padding: 0 100px; */
    width: 85%;
    margin-top: 50px;
}

.container h3 {
    margin-bottom: 30px;
    font-family: Oswald;
    font-size: 30px;
    font-weight: 400;
    text-transform: capitalize;
}

.bold {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
}

.container p {
    line-height: 25px;
}

a {
    text-decoration: underline;
}

.img-bg {
    height: 70vh;
    background: url('/src/assets/defence-img2.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    color: var(--light);
    padding: 50px 100px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.img-bg p {
    margin-top: 35px;
    width: 70%;
    line-height: 25px;

}

.img-bg h2 {
    font-family: Oswald;
    font-size: 50px;
    font-weight: 400;
    margin-bottom: auto;
    text-transform: uppercase;
}

.implement {
    padding-top: 100px;
}

.implement h3 {
    margin-bottom: 30px;
    font-family: Oswald;
    font-size: 30px;
    font-weight: 400;
    text-transform: capitalize;
}

.info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 30px;
}

.info-text {
    width: 400px;
}

.info-img {
    width: 400px;
}

.info-img {
    width: 400px;

}

.info-img img {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .header h2 {
        font-size: 40px;
    }

    .text {
        width: 70%;
    }

    .img-bg {

        padding: 50px 50px;

    }

    .text {
        width: 80%;
    }

    .container {
        width: 80%;
    }
}

@media only screen and (max-width: 500px) {
    .header h2 {
        font-size: 35px;
    }

    .container h3 {

        font-size: 30px;

    }

    .img-bg {

        padding: 50px 20px;
        background: url('/src/assets/defence-mobile.png');
        background-repeat: no-repeat;
        background-size: cover;

    }

    .text {
        width: 80%;
    }

    .container {
        width: 100%;
    }

    .img-bg p {
        width: 90%;
    }

}</style>