<template>
    <section>
        <a class="review-link" href="/reviews">view more <i class="fa fa-arrow-right" aria-hidden="true"></i></a>
       
    </section>
</template>
<script>

</script>
<style scoped>
section{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
    /* background: var(--dark-purple, #522888); */
    padding-top: 50px;
    padding-bottom: 50px;
}

a{
     /* color: var(--light); */
     font-size: 20px;
}
</style>