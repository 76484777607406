<template>
    <section>
        <div class="container">
            <h1>terms & condition</h1>

            <div class="para">
                <p>By using this site you are bound by these Terms of service and to use the site in accordance with these
                    terms
                    and conditions. our Privacy Policy and any additional terms and conditions that may apply to specific
                    sections of the site or to our services available through the site or otherwise from Hayche.</p>
            </div>

            <div class="info">
                <h5>members signup</h5>
                <p>To use certain features of the Site, you will need a username and password, which you will receive
                    through our registration process. You are responsible for maintaining the confidentiality of the
                    password and account, and are responsible for all activities (whether by you or by others) that occur
                    under your password or account. You agree to notify us immediately of any unauthorized use of your
                    password or account or any other breach of security, and to ensure that you exit from your account at
                    the end of each session. We cannot and will not be liable for any loss or damage arising from your
                    failure to protect your password or account information.</p>
            </div>

            <div class="info">
                <h5>MODIFICATION OF SERVICE</h5>
                <p>We reserve the right at any time and from time to time to modify or discontinue, temporarily or
                    permanently, our site and services (or any part thereof) with or without notice. You agree that we shall
                    not be liable to you or to any third party for any modification, suspension, or discontinuance of our
                    site or services for any reason.</p>
            </div>

            <div class="info">
                <h5>TERMINATION</h5>
                <p>We may cancel or terminate your right to use the site or any part of the site at any time without notice.
                    In the event of cancellation or termination, you are no longer authorized to access the part of the site
                    affected by such cancellation or termination. The restrictions imposed on you with respect to materials
                    downloaded from the site, and the disclaimers and limitations of liabilities set forth in these Terms of
                    Service, shall survive.</p>
            </div>

            <div class="info">
                <h5>FEEDBACK</h5>
                <p>We welcome and encourage you to provide feedback, comments, and suggestions for improvements to the
                    Platform. You acknowledge and agree that any and all feedback provided by way of the Platform or
                    otherwise will be the sole and exclusive property of Hayche, and you hereby irrevocably assign to Hayche
                    and agree to irrevocably assign to Hayche all of your right, title, and interest in and to all feedback,
                    including without limitation all worldwide patent rights, copyright rights, trade secret rights, and
                    other proprietary or intellectual property rights therein. At our request and expense, you will execute
                    documents and take such further acts as we may reasonably request to assist Hayche to acquire, perfect,
                    and maintain its intellectual property rights and other legal protections for the feedback.</p>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.container {
    padding-top: 150px;
    padding-bottom: 150px;
}


.para {
    /* border: 1px solid red; */
    margin-bottom: 50px;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 70px;
}

.container h1 {
    font-size: 30px;
    font-family: Oswald;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 50px;
    text-align: center;
}

.info {
    margin-bottom: 50px;
}

.info h5 {
    text-transform: capitalize;
    font-family: Oswald;
    font-size: 25px;
    font-weight: 300;
    margin-bottom: 20px;
}</style>