<template>
    <section class="container">
        <iframe width="100%" height="600" src="https://www.docdroid.net/suBR05D/womens-self-defense-1-pdf" frameborder="0"
            allowtransparency allowfullscreen></iframe>
    </section>
</template>
<script>

</script>
<style scoped>
.container {
    padding-top: 100px;
    padding-bottom: 100px;
}
</style>